<template style="padding-bottom: 20px">

  <div @click="closeInfoBox()" class="course-list">

    <div class="" style="position:relative; width:100%;height:300px;">
      <v-container class="black--text">
        <v-row style="padding:10px">
          <v-col class="header-left">
            <h1 v-if="user">
              {{$t("Hello")}},<br>
              {{getUserName()}}
            </h1>
            <hr style="border-bottom-color: black !important;width: 100px;margin-top:20px;margin-bottom: 20px;" />
            <p class="black--text"></p>
          </v-col>
          <v-col v-if="false" class="hidden-md-and-down header-right">
            <div class="hr-bg">
              <h3>Neue Kurse</h3>
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="white-text">Spanen 5</v-list-item-title>
                  <v-list-item-subtitle class="white-text">Metalltechnik</v-list-item-subtitle>
                  <v-divider style="border-color:white !important;"></v-divider>
                </v-list-item-content>
              </v-list-item>
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="white-text">Projekt Hubschrauber</v-list-item-title>
                  <v-list-item-subtitle class="white-text">Luftfahrttechnik</v-list-item-subtitle>
                  <v-divider style="border-color:white !important;"></v-divider>
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div v-if="false" class="hidden-md-and-down">
      <h1>{{$t("Latest Activities")}}</h1>
      <v-card
          class="d-flex justify-center mb-6 activityrow"
          flat
          tile
      >
        <v-card
            v-for="n in 4"
            :key="n"
            class="rounded-card"
            outlined
            tile
            width="280"
            height="350"
        >
          Kurs x
        </v-card>
      </v-card>
    </div>
    <h1>{{$t("Meine Veranstaltungen")}}</h1>
    <v-card class="transparent">
      <v-card-title class="white-text" style="display: block;">
        <v-row>
          <v-col>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('')"
              single-line
              hide-details
              class="white-text"
              id="searchfield"

            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <div></div>

        <v-row v-if="false">
          <v-col style="max-width: 600px;">
            <v-autocomplete
                v-model="selectedTags"
                :items="filteredTags"
                filled
                chips
                label="Filter"
                item-text="name"
                item-value="id"
                item-color="dark-grey"
                multiple
                class="tag-filter"
                @change="(event) => updateList(event)"
            >
              <!--                      append-icon="mdi-plus"-->
              <!--                      @click:append="addLabel"-->

              <template v-slot:selection="data">
                <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    :color="data.item.color"
                    text-color="white"
                    @click="data.select"
                    @click:close="removeTag(data.item)"
                >
                  {{ data.item.name }}
                </v-chip>
              </template>
              <template v-slot:item="data">
                <template>
                  <v-list-item-content class="ma-0">
                    <v-list-item-title>
                      <v-chip
                          v-html="data.item.name"
                          class="ma-0"
                          close
                          :color="data.item.color"
                          text-color="white"
                      />
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :header-props="headerProps"
        :items="courses"
        :search="search"
        :items-per-page="15"
        class="elevation-1 transparent white-text"
        :hide-default-footer="courses.length < 5"
        @click:row="itemSelected"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :footer-props="{
          'items-per-page-text':$t('Rows per page')
        }"
        no-data-text="-"
        :no-results-text="$t('No results found')"
      >
        <template v-slot:item.description="{item}">

          <template v-if="(item.description !== '')">
            <!-- @mouseover="openInfoBox($event, item.description)" @mouseout="closeInfoBox()" -->
            <v-icon @click.stop="openInfoBox($event, item.description)"
                    large
            >
              mdi-information-outline
            </v-icon>
          </template>
        </template>
        <template v-slot:item:name="{  }">
          <v-avatar
              color="primary"
              size="56"
          ></v-avatar>
        </template>
        <!--
        <template v-slot:item.author="{ item }">
          <v-avatar
              class="mr-1"
              style="color: white; font-weight: bolder;"
              color="warning"
              size="35"
              small
          >{{uppercaseFirstLetter(item.author.firstName)}}{{uppercaseFirstLetter(item.author.lastName)}}</v-avatar>
          {{item.author.firstName}} {{item.author.lastName}}
        </template>
        -->
        <template v-slot:item.status="{ item }" class="ml-0">
          <v-chip
              :color="item.isCompleted ?  'success' : '#005AA9'"
              class="ma-0"
              small
              style="width: 110px;justify-content: center;"

          >
            {{item.isCompleted ? $t('completed') : $t('running')}}
          </v-chip>
        </template>
        <template v-slot:item.updatedAt="{item}" class="ml-0">
          {{formatLocalDate(item.updatedAt)}}
        </template>
        <template v-slot:item.lastOpened="{item}" class="ml-0">
          {{formatLocalDate(item.lastOpened, true)}}
        </template>
        <template v-slot:footer.page-text="items">
          {{ items.pageStart }} - {{ items.pageStop }} / {{ items.itemsLength }}
        </template>
      </v-data-table>
    </v-card>
    <v-overlay id="s3m-info-overlay" opacity="0" z-index="1000" style="" v-if="showInfo">
      <div class="heading">{{ $t("Information") }}</div>
      <div id="s3m-overlay-content" style="" v-html="div(info)"></div>
    </v-overlay>
    <course-edit-dialog style="padding-bottom: 20px;" ref="courseEditDialog" @updated="fetchCourses"/>
    <confirm-dialog ref="confirmDelete"/>
  </div>
</template>

<script>
import CourseEditDialog from '@/components/CourseEditDialog'
import ConfirmDialog from '@/components/ConfirmDialog'
import APIService from '@/services/APIService'

import {mapState} from "vuex";
import shared from "@/services/shared";
import EventBus from "@/services/EventBus";

const UPLOAD_PATH = process.env.VUE_APP_ROOT_API+"/v1/content/";

export default {
  name: 'CourseList',
  components: { CourseEditDialog, ConfirmDialog},
  data () {
    return {
      courses: [],
      origCourses: [],
      studyProgress: [],
      completedCourses: [],
      instance: "demo",
      search: '',
      instanceString: '',
      showActivityList: true,
      allTags: [],
      selectedTags: [],
      filteredTags: [],
      sortBy: 'lastOpened',
      sortDesc: true,
      showInfo: false,
    }
  },
  async mounted() {
    this.setContentHeader();

  },
  computed: {
    ...mapState(['user']),
    headers(){ return [
      {
        text: this.$t('title'),
        align: 'start',
        sortable: true,
        value: 'title',
        width: 400
      },
      {
        text: this.$t('opened'),
        value: 'lastOpened',
        sortable: true
      },
      {
        text: this.$t('state'),
        value: 'status',
        sortable: true
      },
      {
        text: this.$t('info'),
        value: 'description',
        sortable: false
      }
    ]},
    headerProps(){return{
      sortByText: this.$t('Sort')
    }

    }
  },

  methods: {
    updateList(){
      this.courses = [];
      // eslint-disable-next-line no-unused-vars
      this.origCourses.forEach((value, index) => {
        let addCourse = true;
        // eslint-disable-next-line no-unused-vars
        this.selectedTags.forEach((tag, j) => {

          if(value.tags && value.tags.length > 0) {
            let foundTag = false;
            // eslint-disable-next-line no-unused-vars
            value.tags.forEach((courseTag, k) => {
              //console.log(courseTag.id);
              if(courseTag.id == tag){
                foundTag = true;
              }
            });
            if(!foundTag){
              addCourse = false;
            }
          }else{
            addCourse = false;
          }
        });
        if(addCourse){
          this.courses.push(value);
        }
      });
      //update tag list
      let tags = shared.getAllTagsFromCourses(this.courses);
      this.filteredTags = tags;
    },
    removeTag (item) {
      const index = this.selectedTags.indexOf(item.id)
      if (index >= 0) this.selectedTags.splice(index, 1);
      this.updateList();
    },
    openInfoBox(event, desc){
      this.info = desc;
      this.showInfo = true;
    },
    closeInfoBox(){
      this.showInfo = false;
    },
    div(text) {
      return `<div class="s3m-info-div"> ${text} </div>`
    },
    setContentHeader(){
      EventBus.$emit('showTopImage', true, 300);
    },
    itemSelected(item){
      this.$router.push({name: 'course', params:{ course: item, instance: this.instance }} )
    },
    getUserName(){
      return shared.getUserName(this.user);
    },
    async deleteItem (course) {
      await this.$refs.confirmDelete.show({
        title: 'Kurs entfernen',
        text: `Wollen sie <b>${course.title}</b> entfernen?`,
        confirm: 'entfernen'
      })
      await APIService.deleteCourse(course)
      this.courses = await APIService.getCourses(this.$route.params.instance)
    },
    pagination(data) {
      console.log("Pagination", data)
    },
    pageCount(data) {
      console.log("PageCount", data)
    },
    uppercaseFirstLetter(string) {
      return string ? string.charAt(0).toUpperCase() : ''
    },
    formatLocalDate(date, time = false) {
      if(date == ""){
        return "";
      }
      return shared.formatLocaleDate(date, time);
    },
    async fetchCourses() {
      //get last visited course

      let res = await APIService.getUserEvents(this.user.id, "");

      let openedDates = [];
      if(res != null) {
        for (let i = 0; i < res.results.length; i++) {
          if (res.results[i].type == 'openCourseEvent') {
            openedDates.push(res.results[i]);
          }
        }
      }

      this.studyProgress = await APIService.getStudyProgressUser(this.user.id);
      for(let i=0; i<this.studyProgress.length;i++){
        if(this.studyProgress[i].course != null && this.studyProgress[i].lesson == null && this.studyProgress[i].chapter == null){
          this.completedCourses.push(this.studyProgress[i].course);
        }
      }

      this.origCourses = await APIService.getUserCourses(this.user.id);

      //this.allTags = await APIService.getTags();

      this.filteredTags = shared.getAllTagsFromCourses(this.origCourses);

      this.courses = this.origCourses;
      this.courses.forEach((value) => {
          value.imagePath = "";
          if(value.image){
            value.imagePath = UPLOAD_PATH + value.image.path;
          }
          value.lastOpened = "";
          for(let i=0; i<openedDates.length; i++){
            //console.log(value.id+" == "+openedDates[i].courseId);
            if(value.id == openedDates[i].courseId){

              value.lastOpened = openedDates[i].date;
              break;
            }
          }
          if(this.completedCourses.includes(value.id)){
            value.isCompleted = true;
          }
          value.studyProgress = [];
          this.studyProgress.forEach((prog) => {
            if(prog.course == value.id){
              value.studyProgress.push(prog);

            }
          });
          if (this.studyProgress != null) {
            let completedEvents = [];
            this.studyProgress.forEach((prog) => {
              if(prog.course == value.id){
                completedEvents.push(prog);
              }
            });
            shared.setItemsCompleted(value, completedEvents);
          }

      });

      if(this.instance == 'demo'){
        this.instanceString = 'Demo-';
      }else{
        this.instanceString = '';
      }
    }
  },
  async created() {
    this.instance = this.$route.params.instance;
    this.setContentHeader();
    await this.fetchCourses();
  },
  async beforeRouteEnter(to, from, next) {
    next(vm => vm.instance = to.params.instance)
  },
  async beforeRouteUpdate(to,from,next) {
    this.instance = to.params.instance;
    await this.fetchCourses();
    next(vm => vm.instance = to.params.instance)
  }
}
</script>

<style>
  .transparent{
    background:transparent !important;
  }
  input{
    color:white;
  }
  .v-input input{
    color: white !important;
    background: rgba(255,255,255,0.2);
    border-radius: 5px;
    padding-left: 5px;
  }
  .v-icon{
    ;
  }
  input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1; /* Firefox */
  }
  .v-input__control > .v-input__slot:after{
    content: none !important;
  }
  .v-input__control > .v-input__slot:before{
    content: none !important;
  }
  .tile-container{
    max-width: 1240px;
  }
  .v-sheet .activityrow{
    background-color: transparent !important;
    background: transparent !important;
  }
  .lastactcard{
    border-radius: 5px;
    margin:10px;
  }
  #searchfield{
    margin-left: 10px;
    margin-bottom: 10px;
  }
</style>
