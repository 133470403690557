<template>
  <div>
    <h1>{{$t("Events")}}</h1>
    <br>
    <v-btn color="success" @click="loadICS">Termine in Kalender übertragen</v-btn>
    <br><br>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('search')"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="events"
        :search="search"
        :items-per-page="25"
        class="elevation-1"
        :hide-default-footer="events.length < 5"
        sortBy="startDate"
        update: sort-asc
        @click:row="itemSelected"
        :footer-props="{
          'items-per-page-text':$t('Rows per page')
        }"
        no-data-text="-"
        :no-results-text="$t('No results found')"
      >
        <template v-slot:item.title="{ item }">
          {{item.title}}
        </template>
        <!--
        <template v-slot:item.author="{ item }">
          <v-avatar
              class="mr-1"
              style="color: white; font-weight: bolder;"
              color="warning"
              size="35"
              small
          >{{uppercaseFirstLetter(item.author.firstName)}}{{uppercaseFirstLetter(item.author.lastName)}}</v-avatar>
          {{item.author.firstName}} {{item.author.lastName}}
        </template>
        -->
        <template v-slot:item.description="{ item }" class="ml-0">
          <div v-html="item.description"></div>
        </template>
        <template v-slot:item.startDate="{item}" class="ml-0">
          {{formatDate(item.startDate)}}
        </template>
        <template v-slot:item.endDate="{item}" class="ml-0">
          {{formatDate(item.endDate)}}
        </template>
        <template v-slot:footer.page-text="items">
          {{ items.pageStart }} - {{ items.pageStop }} / {{ items.itemsLength }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>

import {mapState} from "vuex";
import shared from "@/services/shared";

export default {
  name: 'EventList',
  components: {},
  props: ['course'],
  data () {
    return {
      events: [],
      search: '',
      instanceString: '',
    }
  },
  computed: {
    ...mapState(['user']),
    headers(){ return [
      {
        text: this.$t('title'),
        align: 'start',
        sortable: true,
        value: 'title',
        width: 400
      },
      {
        text: this.$t('Description'),
        value: 'description',
        sortable: true
      },
      {
        text: this.$t('date'),
        value: 'startDate',
        sortable: true
      },
      {
        text: this.$t('bis'),
        value: 'endDate',
        sortable: true
      },
    ]},
  },

  methods: {
    loadICS(){
      let language = "de-de";
      this.$ics.removeAllEvents();
      for(let i=0; i < this.events.length; i++){
        this.$ics.addEvent(language, this.events[i].title, this.events[i].description, "", this.events[i].startDate, "", "")
      }
      this.$ics.download(this.course.title);

    },
    formatDate(date){
      return shared.formatLocaleDate(date, true);
    },
    itemSelected(item){
      console.log(item);
      //this.$router.push({name: 'course', params:{ course: item, instance: this.instance }} )
    },
    pagination(data) {
      console.log("Pagination", data)
    },
    pageCount(data) {
      console.log("PageCount", data)
    },
    uppercaseFirstLetter(string) {
      return string ? string.charAt(0).toUpperCase() : ''
    },
    async fetchEvents() {
      this.events = this.course.events;
      //this.setDemoEvents();
    },
    setDemoEvents(){
      let course = new Object();
      course.id = 12;
      course.title = "Termin 1";
      course.status = "abgeschlossen";
      course.description = "<b>Hallo Welt</b><br>Eine 2. Zeile!<br>Eine dritte, längere Zeile! Mal schauen, wie lange die werden kann. Es geht tatsächlich noch weiter!";
      course.date = "29.03.2022";
      course.dateICS = "2022-03-29T01:00:00";
      const courses = [];
      courses.push(course);
      course = new Object();
      course.id = 23;
      course.title = "Termin 2";
      course.description = '<a target="_blank" href="https://www.spiegel.de">Demo Link</a>';
      course.date = "23.03.2022";
      course.dateICS = "2022-03-23T01:00:00";
      courses.push(course);
      this.events = courses;
    }
  },
  async created() {
    this.instance = this.$route.params.instance
    await this.fetchEvents();
  },
  async beforeRouteEnter(to, from, next) {
    next(vm => vm.instance = to.params.instance)
  },
  async beforeRouteUpdate(to,from,next) {
    this.instance = to.params.instance;
    await this.fetchEvents();
    next(vm => vm.instance = to.params.instance)
  }
}
</script>

<style scoped>

</style>
