<template class="course-list-demo" style="padding-bottom: 20px">
  <div class="main_page">
    <iframe class="main_page_frame" src="https://shop.ausbau-akademie.de/veranstaltungen/"></iframe>
  </div>
</template>

<script>
import APIService from '@/services/APIService'
import {format, parseISO} from "date-fns";
import {de} from "date-fns/locale";
//import {mapState} from "vuex";
import EventBus from "@/services/EventBus";

const UPLOAD_PATH = process.env.VUE_APP_ROOT_API+"/v1/content/";

export default {
  name: 'CourseList',
  data () {
    return {
      courses: [],
      instance: "demo",
      search: '',
      instanceString: '',
      showActivityList: true,
    }
  },
  mounted() {
    EventBus.$emit('showTopImage', false);
  },
  computed: {
    //...mapState(['user']),
    headers(){ return [
      {
        text: this.$t('title'),
        align: 'start',
        sortable: true,
        value: 'title',
        width: 400
      },
      {
        text: this.$t('date'),
        value: 'updatedAt',
        sortable: true
      },
      {
        text: this.$t('state'),
        value: 'status',
        sortable: true
      },
    ]},
  },

  methods: {
    pagination(data) {
      console.log("Pagination", data)
    },
    pageCount(data) {
      console.log("PageCount", data)
    },
    uppercaseFirstLetter(string) {
      return string ? string.charAt(0).toUpperCase() : ''
    },
    formatLocaleDate(date) {
      return format(parseISO(date), 'PP', {locale: de})
    },
    async fetchCourses() {

      //await APIService.getCoursesInfo();
      const courses = await APIService.getDemoCourses();
      //await APIService.getCourse("61f2636e5f64f7002fb7d8ff");

      //const courses = await APIService.getUserCourses(this.user.id);
      //console.log("courses fetchetd");
      //console.log(courses);

      let filteredCourses = Array();
      // eslint-disable-next-line no-unused-vars
      courses.forEach((value, index) => {
        let found = true;
        /*if (value.tags && value.tags.length > 0) {
          // eslint-disable-next-line no-unused-vars
          value.tags.forEach((tag, tag_index) => {
            if (tag.name == "Demo") {
              found = true;
            }
          });
        }*/
        if (found) {
          value.imagePath = "";
          if(value.image){
            value.imagePath = UPLOAD_PATH + value.image.path;
          }
          filteredCourses.push(value);
        }
      });

      this.courses = filteredCourses;
      if (this.instance == 'demo') {
        this.instanceString = 'Demo-';
      } else {
        this.instanceString = '';
      }
      //console.log(this.courses);
    }
  },
  async created() {
    this.instance = this.$route.params.instance
    await this.fetchCourses();
  },
  async beforeRouteEnter(to, from, next) {
    next(vm => vm.instance = to.params.instance)
  },
  async beforeRouteUpdate(to,from,next) {
    this.instance = to.params.instance;
    await this.fetchCourses();
    next(vm => vm.instance = to.params.instance)
  },
}
</script>

<style>
  .transparent{
    background:transparent !important;
  }
  input{
    color:white;
  }
  .v-input input{
    color: white !important;
    background: rgba(255,255,255,0.2);
    border-radius: 5px;
    padding-left: 5px;
  }
  .v-icon{
    ;
  }
  input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1; /* Firefox */
  }
  .v-input__control > .v-input__slot:after{
    content: none !important;
  }
  .v-input__control > .v-input__slot:before{
    content: none !important;
  }
  .tile-container{
    max-width: 1240px;
  }
  .v-sheet .activityrow{
    background-color: transparent !important;
    background: transparent !important;
  }
  .lastactcard{
    border-radius: 5px;
    margin:10px;
  }
  #searchfield{
    margin-left: 10px;
    margin-bottom: 10px;
  }
</style>
