var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',[_vm._v(_vm._s(_vm.$t("Events")))]),_c('br'),_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.loadICS}},[_vm._v("Termine in Kalender übertragen")]),_c('br'),_c('br'),_c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer')],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.events,"search":_vm.search,"items-per-page":25,"hide-default-footer":_vm.events.length < 5,"sortBy":"startDate","update:":"","sort-asc":"","footer-props":{
        'items-per-page-text':_vm.$t('Rows per page')
      },"no-data-text":"-","no-results-text":_vm.$t('No results found')},on:{"click:row":_vm.itemSelected},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.title)+" ")]}},{key:"item.description",fn:function(ref){
      var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(item.description)}})]}},{key:"item.startDate",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.startDate))+" ")]}},{key:"item.endDate",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.endDate))+" ")]}},{key:"footer.page-text",fn:function(items){return [_vm._v(" "+_vm._s(items.pageStart)+" - "+_vm._s(items.pageStop)+" / "+_vm._s(items.itemsLength)+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }