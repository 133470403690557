<template>
  <v-row justify="center">
    <v-dialog
        v-model="showDialog"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Benutzer</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="userForm" lazy-validation>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                      :disabled="!!user.id"
                      v-model="user.loginName"
                      label="Benutzername"
                      required
                      :rules="loginNameRules()"

                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      v-model="user.password"
                      label="Passwort"
                      required
                      :type="showPassword ? 'text' : 'password'"
                      @click:append="showPassword = !showPassword"
                      @blur="showPassword=false"
                      :rules=" user && !user.id ? passwordRule : []"
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    md="6"
                >
                  <v-text-field
                      v-model="user.firstName"
                      label="Vorname"
                      required
                      :rules="requiredRule('Vorname')"
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    md="6"
                >
                  <v-text-field
                      v-model="user.lastName"
                      label="Nachname"
                      required
                      :rules="requiredRule('Nachname')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      v-model="user.email"
                      label="E-Mail"
                      required
                      :rules="emailRules()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                      v-model="user.groups"
                      :items="availableGroups"
                      outlined
                      chips
                      small-chips
                      label="Gruppen"
                      item
                      multiple
                      item-text="name"
                      item-value="id"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="close"
          >
            Schließen
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="saveUser"
          >
            {{ user.id ? 'Speichern' : 'Hinzufügen'}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirm-dialog ref="confirmDialog"/>
    <snackbar ref="snackbar"></snackbar>

  </v-row>
</template>

<script>
import APIService from '@/services/APIService'
import ConfirmDialog from '@/components/ConfirmDialog'
import Snackbar from '@/components/Snackbar'
import store from "@/store";
export default {
  name: 'UserEditDialog',
  components: { Snackbar, ConfirmDialog },
  data: () => ({
    showDialog: false,
    showPassword: false,
    user: {},
    availableGroups: [],
    snackbarSuccess: {
      text: 'Benutzer erfolgreich gespeichert',
      icon: 'mdi-check-circle',
      color: 'success'
    },
    isEmailTaken: false,
    isLoginTaken: false,
    oldEmail: undefined,
    passwordRule: [
      value => (value && value.length >= 8) || `Das Passwort muss mindestens 8 Zeichen lang sein.`,
      value => (value && !!value.match(/\d/) && !!value.match(/[a-zA-Z]/)) || 'Das Passwort muss mindestens einen Buchstaben und ein Zahl enthalten'
    ],
  }),
  methods: {
    show (user) {
      this.user = { ...user }
      this.oldEmail = user?.email
      APIService.getGroups().then(groups => {
        this.availableGroups = groups
        this.showDialog = true
      })
    },
    close () {
      this.showDialog = false
      this.user = {}
      this.$refs.userForm.resetValidation()
    },
    async saveUser () {
      if (!this.$refs.userForm.validate()) {
        return
      }
      if(!this.user.id && await APIService.isLoginTaken(this.user.loginName)) {
        this.isLoginTaken = true
        return
      }
      if((!this.user.id || this.isEmailChanged()) && await APIService.isEmailTaken(this.user.email)) {

        this.isEmailTaken = true
        return
      }
      if (this.user.id) {
        APIService.updateUser(this.user).then(() => {
          store.dispatch('fetchUsers')
          this.close()
          this.$refs.snackbar.show(this.snackbarSuccess)
        })
      } else {
        this.user.role = 'user'
        APIService.saveUser(this.user).then(() => {
          store.dispatch('fetchUsers')
          this.close()
          this.$refs.snackbar.show(this.snackbarSuccess)
        })
      }
    },
    isEmailChanged() {
      return this.user.email !== this.oldEmail
    },
    requiredRule (fieldName) {
      return [value => !!value || `${fieldName} erforderlich.`]
    },
    emailRules() {
      return [
        value => !!value || 'Email erforderlich.',
        !this.isEmailTaken || 'Die Email-Adresse ist bereits vergeben.'
      ]
    },
    loginNameRules() {
      return [
        value => !!value || 'Benutzername erforderlich.',
        !this.isLoginTaken || 'Der Benutzername ist bereits vergeben.'
      ]
    }
  },
  watch: {
    "user.email": function() {
      this.isEmailTaken = false
    },
    "user.loginName": function() {
      this.isLoginTaken = false
    }
  }
}
</script>

<style scoped>

</style>
