<template>
  <div class="user-list">
    <h1>{{$t("User List")}}</h1>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col>
            <v-text-field
                class="user-search"
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('search')"
                single-line
                hide-details
            ></v-text-field>
          </v-col>
          <v-spacer/>
          <v-col class="text-right">

          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
          :headers="headers"
          :items="users"
          :search="search"
          :items-per-page="25"
          class="elevation-1"
          :hide-default-footer="users.length < 5"
          :footer-props="{
          'items-per-page-text':$t('Rows per page')
        }"
          no-data-text="-"
          :no-results-text="$t('No results found')"
      >
        <template v-slot:footer.page-text="items">
          {{ items.pageStart }} - {{ items.pageStop }} / {{ items.itemsLength }}
        </template>
      </v-data-table>
    </v-card>
    <user-edit-dialog ref="userEditDialog"/>
    <confirm-dialog ref="confirmDelete"/>
  </div>
</template>

<script>
import store from '@/store/index'
import UserEditDialog from '@/components/UserEditDialog'
import APIService from '@/services/APIService'
import ConfirmDialog from '@/components/ConfirmDialog'
export default {
  name: "UserList",
  components: {
    UserEditDialog,
    ConfirmDialog
  },
  props: ['course'],
  data() {
    return {
      search: '',
      users: [],
      headers: [
        //{ text: 'E-Mail', value: 'email' },
        { text: 'Vorname', value: 'firstName' },
        { text: 'Nachname', value: 'lastName' },
      ]
    }
  },
  methods: {
    async fetchUsers(){
      this.users = await APIService.getCourseUsers(this.course.id);
    },
    createUser () {
      this.$refs.userEditDialog.show()
    },
    editUser (item) {
      this.$refs.userEditDialog.show(item)
    },
    deleteUser: function (user) {
      this.$refs.confirmDelete.show({
        title: 'Benutzer entfernen',
        text: `Wollen sie <b>${user.loginName}</b> entfernen?`,
        confirm: 'entfernen'
      }).then(() => {
        APIService.deleteUser(user).then(() => {
          store.dispatch('fetchUsers')
        })
      })
    },
  },
  mounted() {
    this.fetchUsers();
  },

  async beforeRouteUpdate() {
    await this.fetchUsers();
  }
}
</script>

<style scoped>

</style>
