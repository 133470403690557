<template>
  <v-row justify="center">
    <v-dialog
        v-model="showDialog"
        persistent
        max-width="800px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Kurs</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="courseForm">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                      v-model="course.title"
                      label="Titel"
                      required
                      :rules="courseTitleRules()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <tiptap-vuetify
                      v-model="course.description"
                      :extensions="extensions"
                  />
                </v-col>
                <v-col cols="12">

                  <v-expansion-panels
                      v-model="chapterPanel"
                      multiple
                  >
                    <v-expansion-panel>
                      <v-expansion-panel-header>Kapitel</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <draggable
                            :list="selectedChapters"
                            class="list-group"
                            ghost-class="ghost"
                            :move="checkMove"
                            @start="dragging = true"
                            @end="dragging = false"
                        >
                          <v-card
                              class="list-group-item"
                              v-for="element in selectedChapters"
                              :key="element.id"
                          >
                            <v-card-text>
                              <v-expansion-panels
                                  multiple
                                  flat
                              >
                                <v-expansion-panel>
                                  <v-expansion-panel-header>
                                    <v-row
                                    >
                                      {{ element.title }}
                                      <v-spacer/>
                                      <v-icon
                                          class="mr-5"
                                          color="secondary"
                                          small
                                          @click.stop="deleteItem(element)"
                                      >
                                        mdi-delete
                                      </v-icon>
                                    </v-row>
                                  </v-expansion-panel-header>
                                  <v-expansion-panel-content>
                                    <span v-html="element.description"/>
                                  </v-expansion-panel-content>
                                </v-expansion-panel>
                              </v-expansion-panels>
                            </v-card-text>
                          </v-card>
                        </draggable>
                        <v-row class="mt-2">
                          <v-col>
                            <v-autocomplete
                                v-model="selectedChapter"
                                :items="chapters"
                                cache-items
                                dense
                                class="mx-4"
                                hide-no-data
                                hide-details
                                label="Kapitel"
                                item-text="title"
                                item-value="id"
                            ></v-autocomplete>
                          </v-col>
                          <v-col>
                            <v-btn
                                rounded
                                dense
                                color="primary"
                                :disabled="selectedChapter === null"
                                @click="addChapter"
                            >
                              Hinzufügen
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
                <v-col cols="12">
                  <v-select v-model="course.status" :items="states" label="Status"></v-select>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                      v-model="course.groups"
                      :items="groups"
                      outlined
                      chips
                      small-chips
                      label="Gruppen"
                      item
                      multiple
                      item-text="name"
                      item-value="id"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="close"
          >
            Schließen
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="saveCourse"
          >
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirm-dialog ref="confirmDialog"/>
    <snackbar ref="snackbar"></snackbar>
    <v-snackbar
        v-model="saveSuccess"
        color="success"
        multi-line="multi-line"
        timeout="2000"
    >
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>mdi-check-circle</v-icon>
        <v-layout column>
          <div>Kurs wurde erfolgreich gespeichert</div>
        </v-layout>
      </v-layout>
    </v-snackbar>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import APIService from '@/services/APIService'
import ConfirmDialog from '@/components/ConfirmDialog'
import Snackbar from "@/components/Snackbar";
import draggable from 'vuedraggable'
import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify'

const COURSE_INIT_DATA = {
  groups: [],
  status: 'draft'
}
export default {
  name: 'CourseEditDialog',
  components: { Snackbar, ConfirmDialog, draggable, TiptapVuetify },
  data() {
    return {
      showDialog: false,
      values: [],
      value: null,
      course: [],
      saveSuccess: false,
      isCourseTitleTaken: false,
      parentId: 0,
      snackbarSuccess: {
        text: 'Kurs erfolgreich gespeichert',
        icon: 'mdi-check-circle',
        color: 'success'
      },
      groups: [],
      chapters: [],
      filteredChapters: [],
      states: [
        { text: 'Entwurf', value: 'draft'},
        { text: 'Veröffentlicht', value: 'published' },
      ],
      selectedChapter: null,
      selectedChapters: [],
      dragging: false,
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [Heading, {
          options: {
            levels: [1, 2, 3]
          }
        }],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak
      ],
      chapterPanel: undefined,
      search: '',
      loading: false,
    }
  },
  methods: {
    async create (instance) {
      this.course = { ...COURSE_INIT_DATA, instance, author: this.user.id }
      await this.fetchInstanceData(instance)
      this.showDialog = true
    },
    async edit(course) {
      console.log("Course", course)
      this.course = course
      await this.fetchInstanceData(course.instance)
      this.selectedChapters = this.course?.chapters?.map((chapterId => this.chapters.find(chapter => chapter.id === chapterId)))
      console.log("Selected Chapters", this.selectedChapters)
      this.showDialog = true
    },
    close() {
      this.course = { ...COURSE_INIT_DATA }
      this.selectedChapter = null
      this.selectedChapters = []
      this.chapterPanel = undefined
      this.$refs.courseForm.resetValidation()
      this.showDialog = false
    },
    async saveCourse () {
      if (!this.$refs.courseForm.validate()) {
        return
      }
      // TODO Check if course name for instance is already taken
      // if(!this.course.id && await APIService.isCourseTitleTaken(this.course.name, this.course.instance)) {
      //   this.isCourseTitleTaken = true
      //   return
      // }
      this.course.chapters = this.selectedChapters.map((chapter) => chapter.id)
      await APIService.saveCourse(this.course)
      this.$refs.snackbar.show(this.snackbarSuccess)
      this.$emit('updated')
      this.close()
    },
    requiredRule (fieldName) {
      return [value => !!value || `${fieldName} erforderlich.`]
    },
    courseTitleRules() {
      return [
        value => !!value || 'Kurstitel erforderlich.',
        !this.isCourseTitleTaken || 'Der Kurstitel ist bereits vergeben.'
      ]
    },
    checkMove: function(e) {
      window.console.log("Future index: " + e.draggedContext.futureIndex);
    },
    deleteItem(chapter) {
      console.log("Delete item", chapter)
      this.selectedChapters = this.selectedChapters.filter((_chapter) => chapter.id !== _chapter.id)
    },
    addChapter() {
      if(this.selectedChapters.find((chapter) =>  chapter.id === this.selectedChapter)) {
        console.log("Already added")
        return
      }
      this.selectedChapters.push({...this.chapters.find((chapter) => chapter.id === this.selectedChapter)})
      this.selectedChapter = null
    },
    async fetchInstanceData(instance) {
      const result = await Promise.all([
        APIService.getGroups(instance),
        APIService.getChapters(instance)
      ])
      console.log("Result", result)
      this.groups = result[0]
      this.chapters = result[1]
    }
  },
  computed: {
    ...mapState(['user']),
  },
  watch: {
    "course.name"() {
      this.isCourseTitleTaken = false
    },
    search(newVal) {
      console.log("Search changed", newVal)
      if(!newVal) {
        return
      }
      this.loading = true
      setTimeout(() => {
        this.filteredChapters = this.chapters.filter((chapter) => chapter.title.includes(newVal))
        this.loading = false
      }, 5000)
    }
  }
}
</script>

<style scoped>
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
