<template style="padding:5px;">
  <video-player
      class="video-player-box"
      ref="videoPlayer"
      :options="playerOptions"
      :playsinline="true"
      customEventName="customstatechangedeventname"
      style="margin-left:auto;margin-right:auto;display:table-cell;align-content:center;padding:5px;text-align: center;padding-bottom: 50px;"
  ></video-player>
</template>

    <script>
    import 'video.js/dist/video-js.css'

    import { videoPlayer } from 'vue-video-player'

    export default {
      name: 'VideoPlayer',
      components: {
        videoPlayer
      },
      data() {
        return {
          playerOptions: {
            // videojs options
            muted: true,
            language: 'en',
            playbackRates: [0.7, 1.0, 1.5, 2.0],
            sources: [{
              type: "video/mp4",
              src: ""
            }],
            poster: "/static/images/author.jpg",
            responsive: true,
            //fluid: true,
          }
        }
      },
      props: ['url'],
      mounted() {
        this.playerOptions.width = document.documentElement.clientWidth-40;
        this.playerOptions.sources[0].src = this.url;
      },
      computed: {
        player: function () {
          return this.$refs.videoPlayer.player
        }
      },
      methods: {
        /*
        // listen event
        onPlayerPlay(player) {
          // console.log('player play!', player)
        },
        onPlayerPause(player) {
          // console.log('player pause!', player)
        },
        // ...player event

        // or listen state event
        playerStateChanged(playerCurrentState) {
          // console.log('player current update state', playerCurrentState)
        },

        // player is ready
        playerReadied(player) {
          console.log('the player is readied', player)
          // you can use it to do something...
          // player.[methods]
        }*/
      }
    }


    </script>
<style>

</style>
