<template>
  <div id="xpdfviewer" ref="viewer">

  </div>
</template>

<script>
/* eslint-disable */
import WebViewer from "@pdftron/pdfjs-express-viewer";
import EventBus from "@/services/EventBus";

export default {
  name: "WebViewer",
  props: {
    path: String,
    url: String,
    downloadable: Boolean
  },
  data () {
    return {
      viewerInstance: null
    }
  },
  methods:{
    loadViewer(url){
      WebViewer(
          {
            path: this.path,
            //initialDoc: 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf',
            licenseKey: process.env.VUE_APP_PDFJS_KEY,
            disabledElements: [
              'freeHandToolButton',
              'contextMenuPopup'
            ]
          },
          this.$refs.viewer
      ).then((instance) => {

        this.viewerInstance = instance;

        //instance.UI.loadDocument('https://s2.click2meet.de:3000/v1/content/ae/1e/ae1e63ab43954b6e60524d44ef8b1fe42c4d4dcc3263e224875b5be21f262177.pdf', { filename: 'myfile.pdf' });
        this.viewerInstance.UI.loadDocument(this.url, { filename: 'file.pdf' });

        // now you can access APIs through the WebViewer instance
        const { Core, UI } = this.viewerInstance;

        // adding an event listener for when a document is loaded
        Core.documentViewer.addEventListener("documentLoaded", () => {
          console.log("document loaded");
        });

        // adding an event listener for when the page number has changed
        Core.documentViewer.addEventListener(
            "pageNumberUpdated",
            (pageNumber) => {
              console.log(`Page number is: ${pageNumber}`);
            }
        );

        if(!this.downloadable) {
          UI.disableElements(['printButton', 'downloadButton']);
        }
        // adds a button to the header that on click sets the page to the next page
        UI.setHeaderItems((header) => {
          header.push({
            type: "actionButton",
            img: "https://icons.getbootstrap.com/assets/icons/caret-right-fill.svg",
            onClick: () => {
              const currentPage = Core.documentViewer.getCurrentPage();
              const totalPages = Core.documentViewer.getPageCount();
              const atLastPage = currentPage === totalPages;

              if (atLastPage) {
                Core.documentViewer.setCurrentPage(1);
              } else {
                Core.documentViewer.setCurrentPage(currentPage + 1);
              }
            },
          });
        });
      });
    },
    loadPDF(url){
      this.viewerInstance.UI.loadDocument(url, { filename: 'file.pdf' });
    }
  },
  mounted: function () {
    console.log("mounted viewer");
    // eslint-disable-next-line no-unused-vars
    EventBus.$on('overlayContentChanged', (args) => {
      console.log("webviewer type: "+args.type);
      if(args.type != "pdf") return;
      this.loadPDF(args.url);

    });
    this.loadViewer(this.url);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div {
  width: 100%;
  height: 100vh;
}
</style>
